// Generated by Framer (0f22d58)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, Image, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Youtube as YouTube } from "https://framerusercontent.com/modules/NEd4VmDdsxM3StIUbddO/bZxrMUxBPAhoXlARkK9C/YouTube.js";
const YouTubeFonts = getFonts(YouTube);

const cycleOrder = ["WqR6vVElG"];

const variantClassNames = {WqR6vVElG: "framer-v-29fqxw"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, schattenDarstellen, width, ...props}) => { return {...props, dD1T0e5lE: schattenDarstellen ?? props.dD1T0e5lE ?? true} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;schattenDarstellen?: boolean; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, dD1T0e5lE, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "WqR6vVElG", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-XhlYV", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-29fqxw", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"WqR6vVElG"} ref={ref} style={{...style}}>{dD1T0e5lE && (<Image background={{alt: "", fit: "fill", intrinsicHeight: 295, intrinsicWidth: 924, pixelHeight: 295, pixelWidth: 924, sizes: "calc(min(420px, 100vw) * 2.6)", src: new URL("assets/TJi2VWHuHQaoTGglcGTyiQGrFvo.png", import.meta.url).href, srcSet: `${new URL("assets/512/TJi2VWHuHQaoTGglcGTyiQGrFvo.png", import.meta.url).href} 512w, ${new URL("assets/TJi2VWHuHQaoTGglcGTyiQGrFvo.png", import.meta.url).href} 924w`}} className={"framer-1qj0tzy"} data-framer-name={"Schatten"} layoutDependency={layoutDependency} layoutId={"SS2B7NGiF"}/>)}<Image background={{alt: "", fit: "fill", intrinsicHeight: 848, intrinsicWidth: 434, pixelHeight: 848, pixelWidth: 434, sizes: "min(420px, 100vw)", src: new URL("assets/nyAl9RUYjkwoFFBM5ONAQfFs.png", import.meta.url).href, srcSet: `${new URL("assets/512/nyAl9RUYjkwoFFBM5ONAQfFs.png", import.meta.url).href} 262w, ${new URL("assets/nyAl9RUYjkwoFFBM5ONAQfFs.png", import.meta.url).href} 434w`}} className={"framer-7mfl1v"} data-framer-name={"Rahmen"} layoutDependency={layoutDependency} layoutId={"eGD6idzE3"}/><motion.div className={"framer-guebtm-container"} layoutDependency={layoutDependency} layoutId={"ngWHBecT0-container"}><YouTube borderRadius={43} bottomLeftRadius={43} bottomRightRadius={43} height={"100%"} id={"ngWHBecT0"} isMixedBorderRadius={false} isRed={false} layoutId={"ngWHBecT0"} play={"Off"} shouldMute style={{height: "100%", width: "100%"}} thumbnail={"Off"} topLeftRadius={43} topRightRadius={43} url={"https://youtu.be/EZohys8qjtg"} width={"100%"}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-XhlYV [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-XhlYV .framer-wlz4tk { display: block; }", ".framer-XhlYV .framer-29fqxw { height: 843px; position: relative; width: 420px; }", ".framer-XhlYV .framer-1qj0tzy { flex: none; height: 26%; left: calc(55.00000000000003% - 260% / 2); position: absolute; top: calc(97.86476868327404% - 26.097271648873072% / 2); width: 260%; }", ".framer-XhlYV .framer-7mfl1v { flex: none; height: 100%; left: calc(50.00000000000002% - 100% / 2); position: absolute; top: calc(49.940688018979856% - 100% / 2); width: 100%; }", ".framer-XhlYV .framer-guebtm-container { flex: none; height: 94%; left: calc(50.23809523809526% - 87.14285714285714% / 2); position: absolute; top: calc(49.58481613285886% - 94.18742586002372% / 2); width: 87%; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 843
 * @framerIntrinsicWidth 420
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"dD1T0e5lE":"schattenDarstellen"}
 */
const FrameruVuYvvg7v: React.ComponentType<Props> = withCSS(Component, css, "framer-XhlYV") as typeof Component;
export default FrameruVuYvvg7v;

FrameruVuYvvg7v.displayName = "Mockup iPhone Video";

FrameruVuYvvg7v.defaultProps = {height: 843, width: 420};

addPropertyControls(FrameruVuYvvg7v, {dD1T0e5lE: {defaultValue: true, title: "Schatten darstellen?", type: ControlType.Boolean}} as any)

addFonts(FrameruVuYvvg7v, [...YouTubeFonts])